import React from "react"
import Banner from "../components/Banner"
import Footer from "../components/Footer"
import Four from "../components/Four"
import Gmap from "../components/Gmap"
import Head from "../components/Head"
import Layout from "../components/layout"
import Main from "../components/Main"
import SEO from "../components/seo"
import Sponzorji from "../components/Sponzorji"
import Three from "../components/Three"
import Two from "../components/Two"

export default function Home() {
  return (
    <Layout>
      <SEO title="Aikido" />
      <Banner />
      <Main />
      <Two />
      <Three />
      <Gmap />
      <Four />
      <Sponzorji />
    </Layout>
  )
}
