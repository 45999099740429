import React from "react"
import bannerImage from "../img/banner-two.jpg"

const Banner = () => {
  return (
    <div class="akc-banner">
      <div
        class="akc-banner-container"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <div class="akc-banner-content">
          <div class="akc-banner-content-text">
            <div class="akc-banner-inner">
              {" "}
              <h1>Aikido klub Celje</h1>
              <h3>24 let izkušenj, tradicije in znanja</h3>
              <button>O klubu</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
