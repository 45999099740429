import React from "react"

const Gmap = () => {
  return (
    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe
          width="100%"
          height="350"
          id="gmap_canvas"
          src="https://www.google.com/maps/embed/v1/place?q=Lopata+12,+Celje,+Slovenia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
        <style></style>
      </div>
    </div>
  )
}

export default Gmap
