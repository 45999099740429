import React from "react"
import klubImage from "../img/two.jpg"

const Two = () => {
  return (
    <div class="akc-two-section">
      <div class="akc-two-section-container">
        <div
          class="akc-two-left"
          style={{ backgroundImage: `url(${klubImage})` }}
        ></div>
        <div class="akc-two-right">
          <h2>Aikido klub Celje</h2>

          <p>
            Aikido klub Celje, katerega dejavnost sega že v leto 1997, danes
            šteje več kot 60 aktivnih članov. Aktivnost ni posledica zgolj
            rednega treniranja, temveč tudi udeležavanja tako mednarodnih kot
            nacionalnih seminarjev. Spekter znanja tako zajema več vej aikida.
            <br />
            <br />
            Nivo klubske atmosfere se poleg socializacije na blazinah, vzdržuje
            še izven telovadnice, in sicer v obliki piknikov, nočnih pohodov,
            praznovanj, ipd.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Two
