import React, { useContext } from "react"
import MainContext from "./MainContext"

const Three = () => {
  const mainContext = useContext(MainContext)
  const { treningiRef } = mainContext
  return (
    <div class="mid-section-three" ref={treningiRef}>
      <div class="mid-section-three-title">URNIK TRENINGOV</div>
      <div class="mid-section-three__item">
        <h2>Torek</h2>
        <p>Trening za vse</p>
        <p><b>Judo Klub Sankaku, Lopata 12, 3000 Celje </b></p>
        <i class="fas fa-angle-down"></i>
        <h3>20:00 - 21:30</h3>
      </div>
      <div class="mid-section-three__item">
        <h2>Sreda</h2>
        <p>Trening za začetnike</p>
 <p><b>Judo Klub Sankaku, Lopata 12, 3000 Celje </b></p>
        <i class="fas fa-angle-down"></i>
        <h3>20:00 - 21:30</h3>
      </div>
      <div class="mid-section-three__item">
        <h2>Četrtek</h2>
        <p>Trening za vse</p>
        <p><b>Judo Klub Sankaku, Lopata 12, 3000 Celje </b></p>
        <i class="fas fa-angle-down"></i>
        <h3>20:00 - 21:30</h3>
      </div>
    </div>
  )
}

export default Three
