import React from "react"
import mikImage from "../img/mik.jpg"
import visiusImage from "../img/visius.jpg"

const Sponzorji = () => {
  return (
    <div class="akc-sponzorji">
      <h1>Sponzorji kluba</h1>
      <div class="akc-sponzorji-container">
        <div
          class="akc-sponzorji-left"
          style={{ backgroundImage: `url(${mikImage})` }}
        ></div>
        <div
          class="akc-sponzorji-right"
          style={{ backgroundImage: `url(${visiusImage})` }}
        ></div>
      </div>
    </div>
  )
}

export default Sponzorji
