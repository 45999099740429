import React from "react"
import imgSoko2 from "../img/banner-two.jpg"
import nis2019 from "../img/nis2019.jpg"
import uchi2020 from "../img/uchi2020.jpg"
import nis2021 from "../img/nis2021.jpg"
import soko2017 from "../img/soko2017.png"
import obletnica from "../img/aikido20.jpg"
import { Link } from "gatsby"

const Four = () => {
  const edges = [
    {
      title: "Niš 2021",
      link: "norveska",
      sourceUrl: "sokobanja2021",
      imageUrl: nis2021,
    },
    {
      title: "Uchideshi 2020 ",
      link: "norveska",
      sourceUrl: "Uchideshi2020",
      imageUrl: uchi2020,
    },
    {
      title: "Niš 2019",
      link: "norveska",
      sourceUrl: "Nis2019",
      imageUrl: nis2019,
    },
    {
      title: "Sokobanja 2018",
      link: "norveska",
      sourceUrl: "Sokobanja2018",
      imageUrl: imgSoko2,
    },
    {
      title: "20. obletnica kluba",
      link: "norveska",
      sourceUrl: "Sokobanja2018",
      imageUrl: obletnica,
    },
    {
      title: "Sokobanja 2017",
      link: "norveska",
      sourceUrl: "Sokobanja2017",
      imageUrl: soko2017,
    },
  ]
  return (
    <div class="akc-four">
      {edges ? (
        edges.map(item => {
          return (
            <div class="akc-four-item">
              <div
                class="akc-four-item-img"
                style={{ backgroundImage: `url(${item.imageUrl})` }}
              ></div>
              <div class="akc-four-item-sub">
                <Link to={`/slike/${item.sourceUrl}`}>
                  <h4>{item.title}</h4>
                </Link>
              </div>
            </div>
          )
        })
      ) : (
        <p>Nalagam</p>
      )}
      <Link to="/galerija">
        <button>Galerija</button>
      </Link>
    </div>
  )
}

export default Four
