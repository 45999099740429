import React from "react"

const Main = () => {
  return (
    <div class="akc-main-section">
      <div class="akc-main-section-title">
        <h1>Kaj je aikido?</h1>
        <h3>
          Aikido je defenzivna borilna veščina in predstavlja obliko samoobrambe
        </h3>
      </div>
      <div class="akc-main-section-four">
        <div class="akc-main-section-four-item">
          <div class="akc-main-item-icon">
            {" "}
            <i class="fas fa-shapes"></i>
          </div>
          <h2>Tehnika</h2>
          <p>
            {" "}
            Širok spekter tehnik, ki temeljijo na načelu energije in gibanja.
          </p>
        </div>
        <div class="akc-main-section-four-item">
          <div class="akc-main-item-icon">
            <i class="fas fa-users"></i>
          </div>
          <h2>Fleksibilnost</h2>
          <p>Namenjen je vsem, ne glede na spol ali starost.</p>
        </div>
        <div class="akc-main-section-four-item">
          <div class="akc-main-item-icon">
            <i class="fas fa-user-shield"></i>
          </div>
          <h2>Obramba</h2>
          <p>Uporaba napadalčeve energije v svojo korist.</p>
        </div>
        <div class="akc-main-section-four-item">
          <div class="akc-main-item-icon">
            <i class="fas fa-user-friends"></i>
          </div>
          <h2>Druženje</h2>
          <p>Seminarji, pikniki, klubski pohodi in druženja.</p>
        </div>
      </div>
    </div>
  )
}

export default Main
